import axios from 'axios';
import { useQuery, QueryFunctionContext } from 'react-query';
import { decode } from 'js-base64';

const wikiApiUrl = 'https://en.wikipedia.org/w/api.php?origin=*&format=json';
const wikiQueryUrl = `${wikiApiUrl}&action=query`;

interface PageInfo_t
{
	id: number;
	ns: number;
	title: string;
};

interface RandomList_t
{
	batchcomplete: string;
	continue: {
		rncontinue: string;
		continue: string;
	};
	query: {
		random: PageInfo_t[];
	}
};

export interface MetadataValue_t
{
	source: string;
	value: string | number;
	hidden?: boolean;
}

export interface ExtMetadata_t
{
	Artist?: MetadataValue_t;
	Assessments?: MetadataValue_t;
	AttributionRequired?: MetadataValue_t;
	Categories?: MetadataValue_t;
	CommonsMetadataExtensions?: MetadataValue_t;
	Copyrighted?: MetadataValue_t;
	Credit?: MetadataValue_t;
	DateTime?: MetadataValue_t;
	DateTimeOriginal?: MetadataValue_t;
	GPSLatitude?: MetadataValue_t;
	GPSLongitude?: MetadataValue_t;
	GPSMapDatum?: MetadataValue_t;
	ImageDescription?: MetadataValue_t;
	License?: MetadataValue_t;
	LicenseShortName?: MetadataValue_t;
	LicenseUrl?: MetadataValue_t;
	ObjectName?: MetadataValue_t;
	Permission?: MetadataValue_t;
	Restrictions?: MetadataValue_t;
	UsageTerms?: MetadataValue_t;
}

export interface ImageInfo_t
{
	title?: string;
	size: number;
	width: number;
	height: number;
	url: string;
	descriptionurl: string;
	descriptionshorturl: string;
	mime: string;
	badfile: string;
	extmetadata: ExtMetadata_t;
};



export interface Image_t
{
	pageid?: number;
	ns: number;
	title: string;
	missing?: string;
	known?: string;
	imagerepository: string;
	imageinfo: ImageInfo_t[];
}

interface Category_t
{
	ns: number;
	title: string;
}

interface PageCategories_t
{
	pageid: number;
	ns: number;
	title: string;
	categories: Category_t[];
}

interface Categories_t
{
	query: {
		pages: Dictionary<PageCategories_t>
	}
}

interface Dictionary<T>
{
	[key: string]: T;
};

interface ImageList_t
{
	batchcomplete: string;

	query: {
		pages: Dictionary<Image_t>;
	}
};

const k_ForcePages: PageInfo_t[] = [
	// { id: 45363074, ns: 0, title: 'Maine State Route 188' }, // Has map
	// { id: 43122624, ns: 0, title: 'Waynesville Formation' }, // Has dumb tiny icons
	// { id: 18061969, ns: 0, title: 'Drawsko, Greater Poland Voivodeship' }, // Broken image
	// { id: 69088484, ns: 0, title: 'Weston (CDP), Vermont' }, // Only SVGs
];

async function GetRandomList( rncontinue?: string, numToRequest: number = 10 )
{
	try
	{
		if ( k_ForcePages.length )
		{
			return {
				batchcomplete: '',
				continue: { rncontinue: '', continue: '' },
				query: { random: k_ForcePages }
			};
		}

		const response = await axios.get<RandomList_t>( wikiQueryUrl,
			{
				params: {
					list: 'random',
					rnlimit: numToRequest,
					rnnamespace: 0,
					rncontinue: rncontinue,
				},
				headers: {
					'Content-Type': 'application/json',
				}
			} )
		return response.data;
	}
	catch ( e: any )
	{
		console.error( e, e.request );
		throw e;
	}
}

async function GetPageCategories( pageid: number )
{
	const response = await axios.get<Categories_t>( wikiQueryUrl,
		{
			params: {
				prop: 'categories',
				pageids: pageid,
				cllimit: 'max',
				clcategories: k_rgBannedCategories.join( '|' ),
			},
			headers: {
				'Content-Type': 'application/json',
			}
		} );
	// console.log( id, response.data, Object.values( objectMap( response.data.query?.pages, ( page: any ) => page.imageinfo[0].url ) ) );
	return response.data;
}

async function GetImageList( pageid: number )
{
	const response = await axios.get<ImageList_t>( wikiQueryUrl,
		{
			params: {
				generator: 'images',
				prop: 'imageinfo',
				iiprop: 'url|size|mime|badfile|extmetadata',
				pageids: pageid,
				gimlimit: 'max',
			},
			headers: {
				'Content-Type': 'application/json',
			}
		} );
	// console.log( id, response.data, Object.values( objectMap( response.data.query?.pages, ( page: any ) => page.imageinfo[0].url ) ) );
	return response.data;

}

const k_nMinImages = 6;
const k_nMinImageSize = 512;
const k_nMinImageWidth = 200;

const k_nMaxIterations = 5;

export const k_mimeSVG = 'image/svg+xml';
const k_rgGoodMediaTypes = ['image/jpg', 'image/jpeg', 'image/png', k_mimeSVG];
const k_rgBannedTitles = [
	'File:Commons-logo.svg',
	'File:MediaWiki-2020-icon.svg',
	'File:Wikimedia_Community_Logo.svg',
	'File:Wikibooks-logo-en-noslogan.svg',
	'File:Wikibooks-logo.svg',
	'File:Wikidata-logo.svg',
	'File:Wikinews-logo.svg',
	'File:Wikiquote-logo.svg',
	'File:Wikisource-logo.svg',
	'File:Wikispecies-logo.svg',
	'File:Wikiversity logo 2017.svg',
	'File:Wiktionary-logo-v2.svg',
	'File:Wiktionary-logo-en-v2.svg',
	'File:Wikivoyage-Logo-v3-icon.svg',
	'File:Question book-new.svg',
	'File:Semi-protection-shackle.svg',
	'File:Speaker Icon.svg',
	'File:Cscr-featured.svg',
	'File:Open Access logo PLoS transparent.svg',
	'File:Blue pencil.svg',
	'File:Information icon4.svg',
	'File:No Picture.jpg',
	'File:Searchtool.svg',
	'File:Openstreetmap logo.svg',
	'File:3d glasses red cyan.svg',
	'File:Commons to Wikidata QuickStatements.svg',
	'File:1downarrow_red.svg',
	'File:1uparrow_green.svg',
	'File:Pending-protection-shackle.svg',
	'File:Unbalanced scales.svg',
	'File:Dollar sign in circle cleaned (PD version).svg',
];

const k_rgBannedCategories = ['Category:All stub articles'];

async function GetFilteredImageList( pageid: number, title: string ): Promise<Image_t[] | null>
{
	const imageInfo = await GetImageList( pageid );

	if ( !imageInfo.query?.pages )
	{
		console.log( `Discarding ${title} with no pages` );
		return null;
	}

	// Remove duplicates
	let mapImages = new Map<string, Image_t>();
	for ( let image of Object.values( imageInfo.query.pages ) )
	{
		mapImages.set( image.imageinfo[0].url, image );
	}

	let rgImages = Array.from( mapImages.values() ).filter( ( image ) =>
	{
		return (
			image.imageinfo[0]?.size >= k_nMinImageSize &&
			image.imageinfo[0]?.width >= k_nMinImageWidth &&
			!image.imageinfo[0].badfile &&
			!k_rgBannedTitles.includes( image.title ) &&
			k_rgGoodMediaTypes.includes( image.imageinfo[0].mime )
		);
	} ).sort( ( a, b ) =>
	{
		return k_rgGoodMediaTypes.indexOf( b.imageinfo[0].mime ) - k_rgGoodMediaTypes.indexOf( a.imageinfo[0].mime ) ||
			a.imageinfo[0].width - b.imageinfo[0].width ||
			a.imageinfo[0].size - b.imageinfo[0].size;
	} );

	// Delete all but the largest 3 SVGs
	const rgSVGs = rgImages.filter( ( image ) => image.imageinfo[0].mime === k_mimeSVG ).slice( -3 );
	rgImages = rgSVGs.concat( rgImages.filter( ( image ) => image.imageinfo[0].mime !== k_mimeSVG ) );

	// BUGBUG: Remove very frequently used images?

	for ( let image of rgImages )
	{
		if ( image.imageinfo[0].url.startsWith( 'https://upload.wikimedia.org' ) )
		{
			// Map URLs to "special" URLs for hotlinking

			// Format is:
			//  https://commons.wikimedia.org/w/index.php?title=Special:Redirect/file/Sample.png
			//
			// Hostname is based on descriptionurl, not url
			const url = new URL( image.imageinfo[0].descriptionurl );
			const redirectURL = `https://${url.hostname}/w/index.php?title=Special:Redirect/file/${url.pathname.substring(url.pathname.lastIndexOf('/') + 1 )}`;
			image.imageinfo[0].url = redirectURL;
		}
		else
		{
			console.warn( 'Not mapping url', image.imageinfo[0].url );
		}
	}

	return rgImages;
}

async function GetAllPageCategories( rgPageIds: number[] )
{
	const response = await axios.get<Categories_t>( wikiQueryUrl,
		{
			params: {
				prop: 'categories',
				pageids: rgPageIds.join('|'),
				cllimit: 'max',
				clcategories: k_rgBannedCategories.join( '|' ),
			},
			headers: {
				'Content-Type': 'application/json',
			}
		} );
	// console.log( id, response.data, Object.values( objectMap( response.data.query?.pages, ( page: any ) => page.imageinfo[0].url ) ) );
	return response.data;
}

export async function GetViableRandomPageData()
{
	try
	{
		let rncontinue: string | undefined = undefined;
		for ( let i = 0; i < k_nMaxIterations; i++ )
		{
			const randomList: RandomList_t = await GetRandomList( rncontinue, 25 );

			const mapCategories = await GetAllPageCategories( randomList.query.random.map( random => random.id ) );

			for ( const p of randomList.query.random )
			{
				if ( mapCategories.query?.pages[p.id] && mapCategories.query?.pages[p.id].categories?.length > 0 )
				{
					// console.log( `Discarding article ${p.title} from banned category ${bannedCategory}` );
					console.log( `Discarding ${p.title} from banned category`, mapCategories.query?.pages[p.id]?.categories[0].title );
					continue;
				}

				const rgImages = await GetFilteredImageList( p.id, p.title );
				if ( rgImages === null )
					continue;

				const nImages = Object.keys( rgImages ).length;
				if ( nImages < k_nMinImages )
				{
					console.log( `Discarding ${p.title} with too few images (${nImages})` );
					continue;
				}

				if ( rgImages[rgImages.length - 1].imageinfo[0].mime === k_rgGoodMediaTypes[k_rgGoodMediaTypes.length - 1] )
				{
					console.log( `Discarding ${p.title} because it only has SVGs` );
					continue;
				}

				// BUGBUG: See if there's a way to tell if there are multiple pages that
				// contain the same images

				// console.log( p, rgImages, rgCategories.map( ( cat ) => cat.title ) );
				return { p, rgImages };
			}
			rncontinue = randomList.continue.rncontinue;
			console.log( 'No matches, getting another batch' );
			await new Promise( r => setTimeout( r, 2000 ) );
		} while ( true );
	}
	catch ( e )
	{
		console.error( e );
		throw e;
	}
}

interface DailyGame_t
{
	gameid: number;
	pageid: number;
	title: string;
	nextAvailable: number;
}

export type DailyData_t = DailyGame_t[];

export async function DownloadDailyData(): Promise<DailyData_t>
{
	try
	{
		const response = await axios.get<string>( 'dailypuzzle.json',
			{
				headers: {
					'Content-Type': 'application/json',
				}
			} );
		const data: DailyData_t = JSON.parse( decode( response.data ) );
		return data;
	}
	catch ( e )
	{
		console.error( e );
	}
	return [];
}

function GetDailyPuzzle( gameid: number, data: DailyData_t )
{
	let bestGame = null;

	for ( let game of data )
	{
		// console.log( game, gameid );
		if ( game.gameid > gameid )
			break;
		bestGame = game;
	}

	return bestGame;
}

export interface GameData_t
{
	gameid?: number;
	nextAvailable?: number;
	bPractice: boolean;
	pageid: number;
	title: string;
	images: ImageInfo_t[];
}

export function GetTodaysGameId()
{
	return new Date().setHours( 0, 0, 0, 0 );
}


export function useViableRandomWikiPage( pageid: number, title: string, bPractice: boolean )
{
	const today = Date.now();

	let gameid: number | undefined;
	let nextAvailable: number | undefined;

	const queryDailyData = useQuery<DailyData_t>( {
		queryKey: ['DailyGame'],
		queryFn: async ( context: QueryFunctionContext ) =>
		{
			return await DownloadDailyData();
		},
		staleTime: 60 * 60 * 1000, // Every hour
	} );

	let dailyGame: DailyGame_t | null = null;
	if ( queryDailyData.isSuccess && queryDailyData.data )
	{
		dailyGame = GetDailyPuzzle( today, queryDailyData.data );
	}

	const bDailyGame = ( !pageid || pageid === dailyGame?.pageid ) && !bPractice;

	const key = [bDailyGame ? 'Daily' : 'Practice'];

	const query = useQuery<GameData_t>( {
		queryKey: key,
		queryFn: async ( context: QueryFunctionContext ) =>
		{
			bPractice = true;

			if ( bDailyGame && dailyGame )
			{
				console.log( 'Loaded daily game' );
				gameid = dailyGame.gameid;
				pageid = dailyGame.pageid;
				title = dailyGame.title;
				nextAvailable = dailyGame.nextAvailable;
				bPractice = false;
			}

			let rgImages: Image_t[] = [];
			if ( pageid )
			{
				const images = await GetFilteredImageList( pageid, title );
				if ( images !== null )
					rgImages = images;
			}
			if ( !pageid || rgImages.length < k_nMinImages )
			{
				const response = await GetViableRandomPageData();
				pageid = response.p.id;
				title = response.p.title;
				rgImages = response.rgImages;
			}

			const data = { gameid: gameid, nextAvailable: nextAvailable, bPractice: bPractice, pageid: pageid, title: title, images: rgImages.map( ( image ) => ( { title: image.title, ...image.imageinfo[0] } ) ) };
			// console.log( data );
			return data;
		},
		enabled: dailyGame !== null,
	} );

	return query;
}
