import React from 'react';

import classnames from 'classnames';
import styles from './countdown.module.scss';

export function Countdown( props: { label?: string, time?: number } & React.HTMLAttributes<HTMLDivElement> )
{
	const { className, label, time = 0, children, ...divProps } = props;
	const [nTimeRemaining, setTimeRemaining] = React.useState( time - new Date().getTime() );
	const refInterval = React.useRef<number | undefined>();

	const UpdateTime = React.useCallback( () =>
	{
		setTimeRemaining( time - new Date().getTime() );
	}, [time] );

	React.useEffect( () =>
	{
		if ( time )
		{
			refInterval.current = window.setInterval( UpdateTime, 1000 );
		}
	}, [time, UpdateTime] );

	const totalSeconds = Math.max( Math.floor( nTimeRemaining / 1000 ), 0 );
	const hours = Math.floor( totalSeconds / ( 60 * 60 ) );
	const minutes = Math.floor( ( totalSeconds - ( hours * 60 * 60 ) ) / 60 );
	const seconds = totalSeconds % 60;


	return (
		<div className={ classnames( styles.CountdownElement, className ) } { ...divProps }>
			{ totalSeconds > 0 && <div className={ styles.Countdown }>
				{ label && <div className={ styles.Label }>{ label }</div> }
				<div className={ styles.Time }>{ hours.toString().padStart( 2, '0' ) }:{ minutes.toString().padStart( 2, '0' ) }:{ seconds.toString().padStart( 2, '0' ) }</div>
			</div>
			}
			{ totalSeconds <= 0 && children }
		</div>
	);
}
