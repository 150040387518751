import React from 'react';
import { ImageInfo_t, GameData_t } from './wikihooks';
import { WikiImage } from './image';
import { Button } from './button';
import { encode } from 'js-base64';
import { Countdown } from './countdown';
import classnames from 'classnames';
import styles from './game.module.scss';



const Images = ( props: { rgImages?: ImageInfo_t[], bComplete: boolean, nGuesses: number } ) =>
{
	const { rgImages, bComplete, nGuesses } = props;
	return (
		<div className={ styles.Images }>
			{ rgImages?.map( ( image, index ) => <WikiImage key={ image.title } imageInfo={ image } visible={ bComplete || nGuesses + 1 > index } /> ) }
		</div>
	);
}

interface Guess_t
{
	guess: string;
	correct: boolean;
}

function Guess( props: { guess: Guess_t, index: number } )
{
	const { guess, index } = props;
	return (
		<div className={ classnames( styles.Guess,  guess.correct && styles.Correct, !guess.guess && styles.Skipped ) }>
			<div className={ styles.Index } >{ index + 1 })</div>
			<div className={ styles.Text }>{ guess.guess.toUpperCase() || '----' }</div>
		</div>
	);
}

function SharePuzzle( props: { pageid: number, title: string, bPractice: boolean, date: Date, bSuccess: boolean, bSurrendered: boolean, guesses: number, total: number } )
{
	const { pageid, title, bPractice, date, bSuccess, bSurrendered, guesses, total } = props;
	const url = GetShareURL( pageid, title );
	const bCopyable = !!window?.navigator?.clipboard;
	const [bCopied, setCopied] = React.useState( false );

	const CopyToClipboard = ( e: React.MouseEvent<HTMLElement> ) =>
	{
		if ( bCopyable )
		{
			let puzzleID = pageid.toString().split( '' ).reverse().join( '' );
			if ( puzzleID[0] === '0' )
			{
				puzzleID = 'A' + puzzleID;
			}

			let title = ( bPractice ? `Wikipicker practice puzzle ${puzzleID}` : `Wikipicker Daily Puzzle for ${ Intl.DateTimeFormat().format( date ) }` ) + `: ${ bSuccess ? guesses : '--' }/${ total }`;
			let guessText = '';
			if ( guesses > 0 )
			{
				guessText = '🟥'.repeat( Math.min( guesses - 1, 9 ) ) + ( guesses > 10 ? '...' : '' );
				if ( bSuccess )
				{
					guessText = guessText + '🟩';
				}
				else
				{
					guessText = guessText + '🟥';
				}
			}

			if ( bSurrendered )
			{
				guessText = guessText + '🏳';
			}
			navigator.clipboard.writeText( [title, guessText, url].join( '\r\n' ) );
			window.setTimeout( () =>
			{
				setCopied( false );
			}, 3000 );
			setCopied( true );
		}
	};

	if ( !bCopyable )
		return null;

	return (
		<Button className={ classnames( styles.Share, bCopyable && styles.Copyable ) } onClick={ CopyToClipboard } >
			<div>{ bCopied ? 'Copied' : 'Share' }</div>
		</Button>
	);
}

function GetShareURL( id: number, title: string )
{
	const url = new URL( window.location.href );
	url.search = '?id=' + encode( JSON.stringify( { id: id, title: title } ) );
	return url.toString();
}

interface SavedGameState_t
{
	pageid: number;
	bCorrect: boolean;
	bSurrendered: boolean;
	rgGuesses: Guess_t[];
}

export interface Stats_t
{
	// BUGBUG: Figure out how to do streaks
	totalGames: number;
	totalWins: number;
	totalSurrenders: number;

	totalImages: number;
	imagesShown: number;
}

const k_EmptyStats: Stats_t = { totalGames: 0, totalWins: 0, totalSurrenders: 0, totalImages: 0, imagesShown: 0 };
export function GetStats(): Stats_t
{
	try
	{
		return JSON.parse( window.localStorage.getItem( 'Wikipicker_stats' ) || 'null' ) || k_EmptyStats;
	}
	catch ( e )
	{
	}
	return k_EmptyStats;
}

function RecordStats( bWon: boolean, bSurrendered: boolean, nTotalImages: number, nImagesShown: number )
{
	const stats: Stats_t = GetStats();

	stats.totalGames++;
	if ( bWon )
		stats.totalWins++;
	if ( bSurrendered )
		stats.totalSurrenders++;

	stats.totalImages += nTotalImages;
	stats.imagesShown += ( bSurrendered ? nTotalImages : nImagesShown );

	window.localStorage.setItem( 'Wikipicker_stats', JSON.stringify( stats ) );
}

function GetSavedGameState(): SavedGameState_t | null
{
	try
	{
		return JSON.parse( window.localStorage.getItem( 'Wikipicker' ) || 'null' );
	}
	catch ( e )
	{
	}
	return null;
}

export function Game( props: { gameData: GameData_t, refetch: () => void } )
{
	const { gameData, refetch } = props;
	const bPractice = gameData.bPractice || !gameData.gameid;

	const [rgGuesses, internalSetGuesses] = React.useState<Guess_t[]>( [] );
	const [bCorrect, setCorrect] = React.useState( false );
	const [bSurrendered, setSurrendered] = React.useState( false );

	const refInput = React.useRef<HTMLInputElement>( null );

	const bComplete = rgGuesses.length === gameData.images.length || bCorrect || bSurrendered;

	const rgImages = gameData.images;

	React.useEffect( () =>
	{
		if ( bPractice )
		{
			setCorrect( false );
			setSurrendered( false );
			internalSetGuesses( [] );
		}

		// Try to load from local storage
		const data = GetSavedGameState();

		if ( data && data.pageid === gameData.pageid )
		{
			// Set data from saved stuff
			setCorrect( data.bCorrect );
			setSurrendered( data.bSurrendered );
			internalSetGuesses( data.rgGuesses );
		}
		else
		{
			setCorrect( false );
			setSurrendered( false );
			internalSetGuesses( [] );
		}
	}, [gameData.pageid, bPractice] );

	const setGuesses = React.useCallback( ( guesses: Guess_t[] ) =>
	{
		if ( !bPractice )
		{
			// Save guesses to local storage
			window.localStorage.setItem( 'Wikipicker', JSON.stringify( { pageid: gameData.pageid, bCorrect: bCorrect, bSurrendered: bSurrendered, rgGuesses: guesses } ) );
		}
		internalSetGuesses( guesses );
	}, [bPractice, bCorrect, bSurrendered, gameData.pageid ] );

	const RecordGameResult = React.useCallback( ( bSuccess: boolean, bSurrendered: boolean, guesses: Guess_t[] ) =>
	{
		if ( !bPractice )
		{
			// Record stats and such
			RecordStats( bSuccess, bSurrendered, rgImages.length, guesses.length );

			// Record results to local storage
			window.localStorage.setItem( 'Wikipicker', JSON.stringify( { pageid: gameData.pageid, bCorrect: bSuccess, bSurrendered: bSurrendered, rgGuesses: guesses } ) );
		}
		console.warn( `Game ${gameData.gameid || gameData.pageid } complete, ${bSuccess ? 'won' : ( bSurrendered ? 'surrendered' : 'lost' )} in ${guesses.length}/${gameData.images.length} guesses. ` );

	}, [ gameData.pageid, gameData.images.length, gameData.gameid, bPractice, rgImages.length ] );

	const MakeGuess = React.useCallback( ( guess: string ) =>
	{
		if ( !refInput.current )
			return;

		const bCorrectGuess = guess === gameData.title.toLowerCase() || guess === `https://en.wikipedia.org/wiki/${gameData.title.replace( ' ', '_' )}`.toLowerCase();

		const guesses = rgGuesses.concat( { guess: guess, correct: bCorrectGuess } );
		setGuesses( guesses );

		if ( bCorrectGuess )
		{
			setCorrect( true );
			RecordGameResult( true, false, guesses );
		}
		else if ( guesses.length === gameData.images.length )
		{
			setCorrect( false );
			RecordGameResult( false, false, guesses );
		}

		refInput.current.value = '';
	}, [gameData.images.length, gameData.title, rgGuesses, RecordGameResult, setGuesses] );

	const OnGuess = React.useCallback( ( e: React.FormEvent<HTMLFormElement> ) =>
	{
		e.preventDefault(); // Don't refresh page when form submits

		if ( !refInput.current || !refInput.current.value )
			return;

		const guess = refInput.current.value.toLowerCase().trim();
		MakeGuess( guess );
	}, [MakeGuess] );

	const OnSkip = React.useCallback( () =>
	{
		MakeGuess( '' );
	}, [MakeGuess] );

	const OnRestart = React.useCallback( () =>
	{
		setCorrect( false );
		setSurrendered( false );
		setGuesses( [] );
		refetch();
	}, [refetch, setGuesses] );

	const OnSurrender = React.useCallback( () =>
	{
		if ( window.confirm( 'Are you sure?' ) )
		{
			setSurrendered( true );
			RecordGameResult( false, true, rgGuesses );
		}
	}, [RecordGameResult, rgGuesses] );


	const dailyDate = new Date();
	dailyDate.setTime( gameData.gameid || 0 );

	return (
		<div className={ styles.Game }>
			{
				bComplete && <div className={ styles.ResultsSection }>
					<div className={ styles.Result }>{ bCorrect ? `You got it in ${rgGuesses.length} guesses!` : 'Sorry, better luck next time!' }</div>
					<a className={ styles.PageLink } target="_blank" rel="noreferrer" href={ 'https://en.wikipedia.org/?curid=' + gameData.pageid }>{ gameData.title }</a>
				</div>
			}

			{ !bPractice && <div className={ styles.DailyGame }>Daily puzzle for { Intl.DateTimeFormat().format( dailyDate ) }</div> }
			{ ( false && bPractice && !gameData.bPractice ) && <div className={ styles.DailyGame }>Sorry, no daily game currently available. Try this practice game instead. </div> }

			<p>{ rgImages.length } total images</p>
			<Images rgImages={ rgImages } bComplete={ bComplete } nGuesses={ rgGuesses.length } />

			{
				!bComplete && <form className={ styles.Controls } onSubmit={ OnGuess }>
					<div>{ rgGuesses.length + 1 }/{ rgImages.length })</div>
					<input ref={ refInput } disabled={ bComplete } />
					<Button type="submit" disabled={ bComplete }>SUBMIT</Button>
					<Button onClick={ OnSkip } type="button" disabled={ bComplete }>Show Next Image</Button>
				</form>
			}

			{ bComplete &&
				<Countdown time={ gameData.nextAvailable } label="Next puzzle in:">
					<Button onClick={ OnRestart }>Get another</Button>
				</Countdown>
			}
			{ !bComplete && <Button onClick={ OnSurrender }>I Give Up</Button> }

			{ bComplete && <SharePuzzle pageid={ gameData.pageid } title={ gameData.title } bPractice={ bPractice } date={ dailyDate } bSuccess={ bCorrect } bSurrendered={ bSurrendered } guesses={ rgGuesses.length } total={ rgImages.length } /> }

			<div className={ styles.Guesses }>
				{ rgGuesses.slice().reverse().map( ( guess, idx ) => <Guess key={ guess.guess + idx } index={ rgGuesses.length - idx - 1 } guess={ guess } /> ) }
			</div>
		</div>
	);
}
