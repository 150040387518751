import React from 'react';
import { ImageInfo_t } from './wikihooks';
import { Popout } from './popout';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import styles from './image.module.scss';



function PopoutImage( props: { open: boolean, onClose: () => void, url: string, width: number, height: number } )
{
	const { open, onClose, url, width, height } = props;
	// const [strTransform, setTransform] = React.useState( '' );
	const refImage = React.useRef<HTMLImageElement>( null );

	const aspectRatio = width / height;

	/* eslint-disable-next-line no-restricted-globals */
	let computedWidth = Math.min( width, screen.availWidth );

	// Cut 80 off the height to account for scrollbars and stuff.  This is just an estimate.
	/* eslint-disable-next-line no-restricted-globals */
	const computedHeight = Math.min( computedWidth / aspectRatio, screen.availHeight - 80 );

	if ( computedWidth > computedHeight * aspectRatio )
	{
		computedWidth = computedHeight * aspectRatio;
	}

	const initialScale = computedWidth / width;
	const [scale, setScale] = React.useState( initialScale );


	const [outer, setOuter] = React.useState < HTMLDivElement | null >( null );
	const refWrapper = React.useRef<ReactZoomPanPinchRef>( null );

	const OnResize = React.useCallback( () =>
	{
		const rect = outer?.getBoundingClientRect();

		if ( rect )
		{
			setScale( rect.width / computedWidth );
			refWrapper.current?.resetTransform();
		}

	}, [outer, computedWidth] );

	// React to the window changing size
	React.useEffect( () =>
	{
		const win = outer?.ownerDocument.defaultView;
		win?.addEventListener( 'resize', OnResize );

		return () => win?.removeEventListener( 'resize', OnResize );
	}, [outer, OnResize] );

	// When the popup closes, reset the scale factor.
	React.useEffect( () =>
	{
		if ( !open )
		{
			setScale( initialScale );
		}
	}, [open, initialScale] );

	// Whenever scale or anything about the popup changes we need to reset the transform as well.
	React.useEffect( () =>
	{
		refWrapper.current?.resetTransform();
	}, [open, url, width, height] );


	return (
		<Popout open={ open } close={ onClose } width={ computedWidth } height={ computedHeight }  >
			<div ref={ setOuter } className={ styles.PopupImage } style={ { overflow: 'hidden', height: '100vh', width: '100vw' } } onKeyDown={ () => { console.log( 'Keydown' ) } } >
				<TransformWrapper ref={ refWrapper } wheel={ { step: 0.2 } } doubleClick={ { step: 0.7 } } pinch={ { step: 5 } } centerZoomedOut={ scale < 1.0 } limitToBounds={ scale >= 1.0 } initialScale={ scale } minScale={ initialScale } >
					<TransformComponent wrapperStyle={ { overflow: 'hidden', backgroundColor: 'black', width: '100vw', height: '100vh' } } contentStyle={ { backgroundColor: 'black', transformOrigin: "0% 0%", width: 'fit-content', height: 'fit-content' } }  >
						<img ref={ refImage } src={ url } alt=""  />
					</TransformComponent>
				</TransformWrapper>
			</div>
		</Popout>
	);
};

function HTMLNode( props: { html: string } & React.HTMLAttributes<HTMLDivElement> )
{

	const OnLoad = React.useCallback( ( el: HTMLDivElement ) =>
	{
		if ( el )
		{
			const anchors = el.getElementsByTagName( 'a' );

			for ( let a of anchors )
			{
				a.target = '_blank';
			}
		}
	}, [] );

	const { html, className, ...divProps } = props;
	return <div ref={ OnLoad } className={ classnames( styles.HTMLNode, className ) } dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize( html ) } } { ...divProps }></div>
}

function AttributionLine( props: { label: string, children: React.ReactNode } )
{
	const { label, children } = props;
	return <tr className={ styles.AttributionLine }><td>{ label }:</td><td>{ children }</td></tr>;
}

function HTMLAttributionLine( props: { label: string, value: string | number | undefined } )
{
	const { label, value } = props;

	if ( !value )
		return null;

	return <tr className = { styles.AttributionLine }><td >{ label }:</td><td><HTMLNode html={ value.toString() }></HTMLNode></td></tr>;
}

function Attribution( props: { image: ImageInfo_t, onClose: () => void } )
{
	const { image, onClose } = props;
	const [bShowSpoilerWarning, setShowSpoilerWarning] = React.useState( true );

	const date = Date.parse( image.extmetadata?.DateTimeOriginal?.value.toString() || image.extmetadata?.DateTime?.value.toString() || '' );

	const OnKeyUp = React.useCallback( ( e: KeyboardEvent ) =>
	{
		if ( e.code === 'Escape' )
		{
			onClose();
		}
	}, [onClose] );

	const OnClick = React.useCallback( ( e: React.MouseEvent ) =>
	{
		onClose();
	}, [onClose] );

	React.useEffect( () =>
	{
		window.addEventListener( 'keyup', OnKeyUp );

		return () => window.removeEventListener( 'keyup', OnKeyUp );
	}, [OnKeyUp] );

	return (
		<div className={ styles.Attributions }>

			<table className={ styles.AttributionWrapper } onClick={ OnClick }>
				<tbody>
					{ !!date &&
						<AttributionLine label="Date">
							{ Intl.DateTimeFormat( navigator.language, { year: 'numeric', month: 'long', day: 'numeric' } ).format( date ) }
						</AttributionLine>
					}
					<HTMLAttributionLine label="Description" value={ image.extmetadata?.ImageDescription?.value } />
					<HTMLAttributionLine label="Artist" value={ image.extmetadata?.Artist?.value } />
					<HTMLAttributionLine label="Source" value={ image.extmetadata?.Credit?.value } />
					<AttributionLine label="License">
						<a target="_blank" rel="noreferrer" href={ image.extmetadata?.LicenseUrl?.value.toString() }>{ image.extmetadata?.License?.value }</a>
					</AttributionLine>
					{ true &&
						<AttributionLine label="Link">
							<a target="_blank" rel="noreferrer" href={ image.descriptionurl }>{ image.descriptionurl }</a>
						</AttributionLine>
					}
				</tbody>
			</table>
			{ bShowSpoilerWarning &&
				<div className={ styles.Spoiler } onClick={ () => { setShowSpoilerWarning( false ) } } >
					Attributions can contain spoilers! Click here to show anyway.
				</div>
			}
			<div className={ styles.AttributionOverlay } onClick={ OnClick }></div>
		</div>
	);
}


export function WikiImage( props: { imageInfo: ImageInfo_t, visible: boolean } )
{
	const { imageInfo, visible } = props;
	const [bLoaded, setLoaded] = React.useState( false );
	const [bIsOpen, setIsOpen] = React.useState( false );
	const [bFailed, setFailed] = React.useState( false );
	const [bShowAttribution, setShowAttribution] = React.useState( false );

	const OnLoad = React.useCallback( () =>
	{
		setLoaded( true );
	}, [] );

	const OnError = React.useCallback( ( event: any ) =>
	{
		console.error( 'Failed to load image', imageInfo, event );
		setFailed( true );
	}, [imageInfo] );

	const OnClick = React.useCallback( ( event: any ) =>
	{
		if ( !bIsOpen )
		{
			setIsOpen( true );
		}
	}, [bIsOpen] );

	const OnClose = React.useCallback( () =>
	{
		if ( bIsOpen )
		{
			setIsOpen( false );
		}
	}, [bIsOpen] );

	if ( bFailed )
	{
		return (
			<div className={ classnames( styles.ImageWrapper, styles.Visible, styles.Error ) }>
				Image Failed To Load
			</div>
		);
	}

	return (
		<div className={ classnames( ( bLoaded && visible ) && styles.Visible, styles.ImageWrapper ) }>
			<div className={ styles.ImageSizer }>
				<img className={ classnames( styles.Image ) } onLoad={ OnLoad } onError={ OnError } onClick={ OnClick } alt={ imageInfo.title } src={ imageInfo.url + '&width=300' } style={ { /* maxWidth: imageInfo.width */ } } />
			</div>
			{ imageInfo.extmetadata.AttributionRequired?.value !== 'false' &&
				<>
					<div className={ styles.AttributionLink } onClick={ () => setShowAttribution( !bShowAttribution ) }>Attribution</div>
				{ bShowAttribution && <Attribution image={ imageInfo } onClose={ () => setShowAttribution( false ) } /> }
				</>
			}
			<PopoutImage open={ bIsOpen } onClose={ OnClose } url={ imageInfo.url } width={ imageInfo.width } height={ imageInfo.height } />
		</div>
	);
}