import { Root } from './root';
import { QueryClient, QueryClientProvider } from 'react-query';

function App()
{
  const queryClient = new QueryClient( {
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    }
  } );

  return (
    <QueryClientProvider client={ queryClient }>
      <Root />
    </QueryClientProvider>
  );
}

export default App;
