import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Stats_t, GetStats } from './game';
import styles from './dialogs.module.scss';


export function useShowInstructions()
{
	return useQuery<boolean>( {
		queryKey: 'Instructions_Visible',
		queryFn: () =>
		{
			return !window.localStorage.getItem( 'Wikipicker_instructions' );
		}
	} );
}

export function useShowOrHideInstructions( bShow: boolean )
{
	const client = useQueryClient();

	return React.useCallback( () =>
	{
		client.setQueryData( 'Instructions_Visible', bShow );
	}, [client, bShow] );
}

function ModalDialog( props: { onClose: () => void, children?: React.ReactNode } )
{
	const { onClose, children } = props;

	const OnKeyUp = React.useCallback( ( e: KeyboardEvent ) =>
	{
		if ( e.code === 'Escape' )
		{
			onClose();
		}
	}, [onClose] );

	React.useEffect( () =>
	{
		window.addEventListener( 'keyup', OnKeyUp );

		return () => window.removeEventListener( 'keyup', OnKeyUp );
	}, [OnKeyUp] );

	return (
		<div className={ styles.ModalDialog } onClick={ props.onClose }>
			<div className={ styles.ModalPosition }>
				{ children }
			</div>
		</div>
	);
}

function InstructionsDialog()
{
	const queryShowInstructions = useShowInstructions();
	const HideInstructions = useShowOrHideInstructions( false );

	React.useEffect( () =>
	{
		if ( queryShowInstructions.data )
		{
			window.localStorage.setItem( 'Wikipicker_instructions', '1' );
		}
	}, [queryShowInstructions.data] );

	if ( !queryShowInstructions.data )
		return null;

	return (
		<ModalDialog onClose={ HideInstructions } >
			<div className={ styles.InstructionsDialog } onClick={ ( e ) => { e.stopPropagation(); } }>
				<div className={ styles.CloseButton } onClick={ HideInstructions }>X</div>
				<h2>How to play</h2>
				<p>The object of the game is to guess the English Wikipedia page that the displayed images are all taken from.</p>
				<p>Pictures will be displayed one by one. After each picture is shown, make your guess by typing the exact title of a Wikipedia page into the input box.</p>
				<p>If you're incorrect, another picture will be displayed until all the pictures are exhausted. You get one guess per picture. The game ends when you run out of guesses or find the correct page.</p>
				<p>You can click on any image to get a larger version in a popup window.</p>
				<p>Use whatever resources you want to solve the puzzle!  (Reverse image search or viewing page source will make it pretty easy, so maybe don't do those). Not all puzzles are solvable.</p>
				<p><b>Hard Mode:</b> Solve the puzzle without searching Wikipedia.</p>
				<p>There is one daily puzzle per day (which resets at midnight Pacific time).  If you want more, toggle "Practice Mode" in the upper left to play a random puzzle.</p>
				<p></p>
				<p>Questions? Comments? You can email us <a href="mailto:wikipicker@wikipicker.com">here</a></p>
			</div>
		</ModalDialog>
	);
}

export function useShowStats()
{
	return useQuery<boolean>( {
		queryKey: 'Stats_Visible',
		queryFn: () =>
		{
			return false;
		}
	} );
}

export function useShowOrHideStats( bShow: boolean )
{
	const client = useQueryClient();

	return React.useCallback( () =>
	{
		client.setQueryData( 'Stats_Visible', bShow );
	}, [client, bShow] );
}

function StatsDialog()
{
	const queryShowStats = useShowStats();
	const HideStats = useShowOrHideStats( false );

	if ( !queryShowStats.data )
		return null;

	const stats: Stats_t = GetStats();
	console.log( stats );

	return (
		<ModalDialog onClose={ HideStats } >
			<div className={ styles.StatsDialog } onClick={ ( e ) => { e.stopPropagation(); } }>
				<div className={ styles.CloseButton } onClick={ HideStats }>X</div>
				<h2>Statistics</h2>
				<div className={ styles.Stats }>
					<p>Total Games: { stats.totalGames }</p>
					<p>Games Won: { stats.totalWins }</p>
					<p>Games Surrendered: { stats.totalSurrenders }</p>
					<p>Total Images: { stats.totalImages }</p>
					<p>Images Seen: { stats.imagesShown }</p>
				</div>
			</div>
		</ModalDialog>
	);
}

export function GlobalDialogs( props: {} )
{
	return (
		<div className={ styles.DialogsRoot } >
			<InstructionsDialog />
			<StatsDialog />
		</div>
	);
}