import React from 'react';
import logo from './logo.svg';
import statsicon from './ftbars.svg';
import { useViableRandomWikiPage, DailyData_t, GetViableRandomPageData, GetTodaysGameId, DownloadDailyData } from './wikihooks';
import { Game } from './game';
import { ToggleElement } from './toggle';
import { GlobalDialogs, useShowOrHideInstructions, useShowOrHideStats } from './dialogs';
import { encode, decode } from 'js-base64';
import styles from './root.module.scss';


function ParseInitialParams( search: string )
{
	if ( search )
	{
		const queryParams = new URLSearchParams( search );
		const data = queryParams.get( 'id' );
		if ( data )
		{
			try
			{
				const o = JSON.parse( decode( data ) );
				if ( o?.id && o?.title )
				{
					return { id: o.id, title: o.title };
				}
			}
			catch ( e )
			{
				console.log( e );
			}
		}
	}
	return {id:0, title: ''};
}

if ( process.env.NODE_ENV !== 'production' )
{
	const PrettyPrintData = ( data: DailyData_t ) =>
	{
		console.log( data.map( ( i ) =>
		{
			const date = new Date( i.gameid );
			return ( { date: ( date.getMonth() + 1 ) + '/' + ( date.getDate() + 1 ), gameid: i.gameid, p: i.pageid} );
		} ) );
	};

	( window as any ).GenerateDailyGames = async ( nTotal: number, startDate?: number, interval?: number ) =>
	{
		const oldData: DailyData_t = await DownloadDailyData();
		interval = interval || 24 * 60 * 60 * 1000;  // Default one day
		let date = ( startDate || oldData[oldData.length - 1].gameid ) + interval;

		// PrettyPrintData( oldData );

		// Keep today's
		const oldest = Date.now() - ( 24 * 60 * 60 * 1000 );
		const data = oldData.filter( x => x.gameid >= oldest && x.gameid <= date );
		// PrettyPrintData( data );

		const nToGenerate = Math.max( nTotal - data.length, 0 );
		console.log( 'Need', nToGenerate, 'new games' );
		for ( let i = 0; i < nToGenerate; i++ )
		{
			const response = await GetViableRandomPageData();
			data.push( { gameid: date, pageid: response.p.id, title: response.p.title, nextAvailable: date + interval } );
			date += interval;
		}

		PrettyPrintData( data );

		console.log( encode( JSON.stringify( data ) ) );
	}
}


export function Root()
{
	const [bPractice, setPractice] = React.useState( false );
	const [initialState, setInitialState] = React.useState( window.location.search );
	const params = ParseInitialParams( initialState );
	const queryPageData = useViableRandomWikiPage( params.id, params.title, bPractice );
	const ShowStats = useShowOrHideStats( true );
	const ShowInstructions = useShowOrHideInstructions( true );

	const TogglePractice = React.useCallback( () =>
	{
		setPractice( !queryPageData.data?.bPractice );
		setInitialState( '' );
	}, [queryPageData.data?.bPractice] );

	const OnComplete = React.useCallback( () =>
	{
		setInitialState( '' )
		queryPageData.refetch();
	}, [queryPageData] );

	const bFetching = queryPageData.isFetching || queryPageData.isIdle;

	return (
		<div className={ styles.App }>
			<div className={ styles.AppHeader }>
				{ bFetching && <img src={ logo } className={ styles.AppLogo } alt="logo" /> }
				{ !bFetching && queryPageData.data && <ToggleElement className={ styles.PracticeModeToggle } selected={ queryPageData.data?.bPractice } onSelect={ TogglePractice } label="Practice Mode" /> }
				<div className={ styles.StatsButton } onClick={ ShowStats }><img src={ statsicon } alt="Stats" /></div>
				<div className={ styles.InstructionsButton } onClick={ ShowInstructions }>?</div>
				{ !bFetching && queryPageData.data && <Game gameData={ queryPageData.data } refetch={ OnComplete } /> }
			</div>
			<GlobalDialogs />
		</div>
	);
}
