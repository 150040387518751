import React from 'react';
import ReactDOM from 'react-dom';


export const Popout = ( props: { open: boolean, width: number, height: number, children: React.ReactNode, close: () => void } ) =>
{
	const { open, width, height, children, close } = props;

	const [el, setEl] = React.useState<HTMLElement>();

	React.useEffect( () =>
	{
		if ( open )
		{
			const features = `resizable=yes,
				location=no,
				menubar=no,
				status=no,
				titlebar=no,
				toolbar=no,
				width=${width},
				height=${height}`;

			const win = window.open( '', '', features );

			if ( !win )
			{
				window.alert( "Popup blocked! Need to allow popups from this site." )
				return;
			}

			win.document.title = 'Wikipicker';
			win.document.body.style.margin = '0';

			const el = document.createElement( "div" );
			win.document.body.appendChild( el );

			win.onbeforeunload = () =>
			{
				close();
			};
			win.onkeyup = (e: KeyboardEvent ) =>
			{
				if ( e.code === 'Escape' )
				{
					win.close();
				}
			}
			setEl( el );

			return () => win.close();
		}
	}, [open, close, height, width] );

	if ( !open || !el )
	{
		return null;
	}

	return ReactDOM.createPortal( children,	el );
}