import classnames from 'classnames';
import styles from './toggle.module.scss';

const Toggle = ( props: { selected: boolean, onSelect: () => void } ) =>
{
	const { selected, onSelect } = props;

	return (
		<div className={ classnames( styles.Toggle, selected && styles.Selected ) } onClick={ onSelect }>
			<div className={ styles.SliderOuter }>
				<div className={ styles.Slider } />
			</div>
		</div>
	);
}

export const ToggleElement = ( props: { className?: string, selected: boolean, onSelect: () => void, label?: string } ) =>
{
	const { className, selected, onSelect, label } = props;

	return (
		<div className={ classnames( styles.ToggleElement, className ) }>
			{ label && <div className={ styles.Label }>{ label }</div> }
			<Toggle selected={ selected } onSelect={ onSelect } />
		</div>
	);
}